/**
 * The Customer Payout tab for sending a querying payouts
 */

import { Button } from '@/components/ui/button';
import { MenuButton } from '@/components/ui/menu-button';
import { useCustomerProfile, useCustomerProfilePermissions } from '@/services/useCustomerProfile';
import { BalanceOverview } from '@/shared/components/BalanceOverview/BalanceOverview';
import BatchFileUpload from '@/shared/components/BatchFileUpload';
import { PageLayout } from '@/shared/components/PageLayout/PageLayout';
import { Tabs } from '@/shared/components/Tabs/Tabs';
import useWindowSize from '@/shared/hooks/useWindowSize';
import { BatchFileType, TabPermissions } from '@/shared/types';
import { Modal, Spinner } from '@checkrx/pay-component-library';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CreatePayoutModal } from './CreatePayoutModal';
import PayoutsBatchFileTable from './PayoutsBatchFileTable';
import PayoutsTable from './PayoutsTable';

type PayoutTabT = 'payouts' | 'batch';

export default function CustomerPayoutsTab() {
  const [selectedTab, setSelectedTab] = useState<PayoutTabT>('payouts');
  const [showCreatePayout, setShowCreatePayout] = useState(false);
  const [showBatchModal, setShowBatchModal] = useState(false);
  const { data: permissions } = useCustomerProfilePermissions();
  const { data: customerProfile, isLoading } = useCustomerProfile();
  const hasWritePermissions = permissions?.payoutsTab === TabPermissions.write;
  const { width } = useWindowSize();

  const [searchParams] = useSearchParams();
  const payWorkerId = searchParams.get('payWorkerId');

  const tabs = [
    {
      label: 'Payouts',
      value: 'payouts' as PayoutTabT,
    },
    {
      label: 'Batch uploads',
      value: 'batch' as PayoutTabT,
    },
  ];

  const menuOptions = [
    {
      label: 'Create Payout',
      action: () => setShowCreatePayout(true),
      state: 'enabled' as const,
    },
    {
      label: 'Upload Payout Batch File',
      action: () => setShowBatchModal(true),
      state: 'enabled' as const,
    },
  ];

  if (isLoading || !customerProfile)
    return (
      <div className="w-[368px] h-[60px] flex items-center gap-3 bg-dividerGrey rounded-[10px] pl-10 cursor-not-allowed text-sm text-textGrey">
        <div>Loading payouts</div> <Spinner size="24px" />
      </div>
    );

  return (
    <PageLayout
      title="Payouts"
      headerLeft={
        <>
          {hasWritePermissions && (
            <Tabs
              tabs={tabs}
              selectedTab={selectedTab}
              onChange={(t) => setSelectedTab(t.value as PayoutTabT)}
            />
          )}
          <BalanceOverview />
        </>
      }
      headerRight={
        hasWritePermissions && (
          <div className="flex gap-4 items-center">
            {width > 1114 ? (
              <>
                <Button onClick={() => setShowCreatePayout(true)} variant="default">
                  Create Payout
                </Button>
                <Button onClick={() => setShowBatchModal(true)} variant="secondary">
                  Upload Payout Batch File
                </Button>
              </>
            ) : (
              <MenuButton options={menuOptions} variant="secondary" />
            )}
          </div>
        )
      }
    >
      <div className="h-full overflow-y-hidden">
        {hasWritePermissions ? (
          selectedTab === 'payouts' ? (
            <PayoutsTable
              payWorkerId={payWorkerId || undefined}
              customerProfile={customerProfile}
            />
          ) : (
            <PayoutsBatchFileTable />
          )
        ) : (
          <PayoutsTable payWorkerId={payWorkerId || undefined} customerProfile={customerProfile} />
        )}
        {/* Extra div at the end for bottom spacing */}
        <div />
      </div>
      <Modal
        isOpen={showCreatePayout}
        close={() => {
          setShowCreatePayout(false);
        }}
        headerText="Create Payout"
        modalContent={<CreatePayoutModal onClose={() => setShowCreatePayout(false)} />}
      />
      <Modal
        isOpen={showBatchModal}
        close={() => {
          setShowBatchModal(false);
        }}
        headerText="Upload Payout Batch File"
        subHeaderText="Pay multiple workers with a single click"
        modalContent={<BatchFileUpload type={BatchFileType.Payout} />}
      />
    </PageLayout>
  );
}

