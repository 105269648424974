/**
 * Sidebar for navigating between views in the Customer Dashboard
 */
import SidebarContainer from './SidebarContainer';

import Logo from '@/shared/assets/logo.svg';
import { Column, LogoImg } from '@/shared/components/Sidebar.styled';
import SidebarLink, { SidebarItemT } from './SidebarItem';

import { useCustomerProfile } from '@/services/useCustomerProfile';
import { CUSTOMER_SUPPORT_URL } from '@/shared/constants';
import { FeatureToggles } from '@/shared/feature-toggles/FeatureToggles';
import useWindowSize from '@/shared/hooks/useWindowSize';
import { FC, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Tooltip from '../Tooltip/Tooltip';
import { CustomerAuthMenu } from './CustomerAuthMenu';

const FlexColumn = styled(Column)<{ collapsed: boolean }>`
  justify-content: space-between;
  transition: width 0.2s ease;
  width: ${(props) => (props.collapsed ? '64px' : '240px')};
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  padding-left: 12px;
  padding-right: 12px;
`;

const BottomContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
`;

const LogoContainer = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WhatsNewWrapper = styled.div<{ collapsed: boolean }>`
  margin-right: ${(props) => (props.collapsed ? '0' : '50px')};
`;

const CollapsedSidebarContainer = styled(SidebarContainer)<{ collapsed: boolean }>`
  width: ${(props) => (props.collapsed ? '60px' : '240px')};
  min-width: ${(props) => (props.collapsed ? '60px' : '240px')};
  transition:
    width 0.2s ease,
    min-width 0.2s ease;
`;

type Props = {
  items: SidebarItemT[];
};

const Sidebar: FC<Props> = ({ items }) => {
  const { pathname } = useLocation();
  const { data: customerProfile } = useCustomerProfile();
  const { width } = useWindowSize();
  const [collapsed, setCollapsed] = useState(false);
  const [showFeatureToggles, setShowFeatureToggles] = useState(false);
  const [_, setLogoClickCount] = useState(0);
  const logoClickTimeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    setCollapsed(width < 1296);
  }, [width]);

  useEffect(() => {
    const handleKeysShow = ({
      metaKey,
      shiftKey,
      key,
    }: {
      metaKey: boolean;
      shiftKey: boolean;
      key: string;
    }) => {
      if (metaKey && shiftKey && key === 'k') {
        setShowFeatureToggles((prev) => !prev);
      }
      if (key === 'Escape') {
        setShowFeatureToggles(false);
      }
    };
    document.addEventListener('keydown', handleKeysShow);
    return () => document.removeEventListener('keydown', handleKeysShow);
  }, []);

  const handleLogoClick = () => {
    setLogoClickCount((prev) => {
      const newCount = prev + 1;

      if (logoClickTimeoutRef.current) {
        clearTimeout(logoClickTimeoutRef.current);
      }

      logoClickTimeoutRef.current = setTimeout(() => {
        setLogoClickCount(0);
      }, 1000);

      if (newCount === 5) {
        setShowFeatureToggles((prev) => !prev);
        setLogoClickCount(0);
        return 0;
      }

      return newCount;
    });
  };

  return (
    <>
      {customerProfile?._id && (
        <launchnotes-embed
          token="public_KUv31DMeh0WRmA72zJ4Q9KEn"
          project="pro_3q0JzmYlDx0mV"
          categories="[ ]"
          toggle-selector="#whats-new-link"
          heading="Here's whats new in your dashboard 🎉"
          heading-color="#FFF"
          subheading="The latest features, improvements, and bug fixes"
          subheading-color="rgba(255, 255, 255, 0.8)"
          primary-color="#009BB5"
          widget-placement="auto"
          widget-offset-skidding="0"
          widget-offset-distance="0"
          show-unread
          anonymous-user-id={`${customerProfile._id}`}
          unread-placement="left-start"
          unread-offset-skidding="3"
          unread-offset-distance="-3"
          unread-background-color="#f56e60"
          unread-text-color="#ffffff"
        ></launchnotes-embed>
      )}

      <FeatureToggles show={showFeatureToggles} />
      <CollapsedSidebarContainer collapsed={collapsed}>
        <FlexColumn collapsed={collapsed}>
          <MainContent>
            <LogoContainer>
              <LogoImg
                src={Logo}
                alt="Logo"
                height={collapsed ? 24 : 32}
                onClick={handleLogoClick}
              />
            </LogoContainer>

            {items.map((item) => (
              <SidebarLink
                key={item.to}
                text={item.text}
                to={item.to}
                icon={item.icon}
                disabled={item?.disabled ?? false}
                active={pathname === item.to}
                collapsed={collapsed}
              />
            ))}
          </MainContent>
          <BottomContent>
            <WhatsNewWrapper id="whats-new-link" collapsed={collapsed}>
              <Tooltip content="What's new?" align="right" enabled={collapsed}>
                <SidebarLink
                  text="What's New 🎉"
                  icon="gift"
                  iconColor="primary"
                  collapsed={collapsed}
                />
              </Tooltip>
            </WhatsNewWrapper>

            <Tooltip content="Need help?" align="right" enabled={collapsed}>
              <SidebarLink
                text="Need help?"
                onClick={() => {
                  window.open(CUSTOMER_SUPPORT_URL, '_blank', 'noopener,noreferrer');
                }}
                icon="help-circle"
                collapsed={collapsed}
              />
            </Tooltip>

            <CustomerAuthMenu collapsed={collapsed} />
          </BottomContent>
        </FlexColumn>
      </CollapsedSidebarContainer>
    </>
  );
};

export default Sidebar;

