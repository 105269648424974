import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const TOGGLE_KEYS = [
  'hide logo',
  'ignore worker creation validation',
  'showNewUi',
  'lockTaxes',
  'showPayoutPurpose',
  'showReportingSidebar',
] as const;
export type ToggleKey = (typeof TOGGLE_KEYS)[number];
export type TOGGLES_MAP = Record<ToggleKey, boolean>;

export const DEFAULT_FEATURE_TOGGLES: TOGGLES_MAP = {
  'hide logo': false,
  'ignore worker creation validation': false,
  showNewUi: false,
  lockTaxes: false,
  showPayoutPurpose: false,
  showReportingSidebar: false,
};

export const FEATURE_TOGGLE_STORAGE_KEY = 'CHECKR_PAY_featureToggles';
const FEATURE_TOGGLE_QUERY_KEY = 'FEATURE_TOGGLES';
const fetchFeatureToggles = async (): Promise<Record<string, boolean>> => {
  const toggles = window?.localStorage?.getItem(FEATURE_TOGGLE_STORAGE_KEY) ?? '{}';
  return toggles ? JSON.parse(toggles) : {};
};

const useFeatureToggles = () => {
  const queryClient = useQueryClient();
  const { data: _featureToggles = {} } = useQuery<Record<string, boolean>>({
    queryKey: [FEATURE_TOGGLE_QUERY_KEY],
    queryFn: fetchFeatureToggles,
  });
  const featureToggles = { ...DEFAULT_FEATURE_TOGGLES, ..._featureToggles };

  const setFeatureToggle = useMutation({
    mutationFn: async ({ key, value }: { key: ToggleKey; value: boolean }) => {
      const currentToggles =
        queryClient.getQueryData<Record<string, boolean>>([FEATURE_TOGGLE_QUERY_KEY]) ?? {};
      const updatedToggles = { ...currentToggles, [key]: value };
      window?.localStorage?.setItem(FEATURE_TOGGLE_STORAGE_KEY, JSON.stringify(updatedToggles));
      queryClient.setQueryData([FEATURE_TOGGLE_QUERY_KEY], updatedToggles);
    },
  });

  const removeFeatureToggle = useMutation({
    mutationFn: async ({ key }: { key: ToggleKey }) => {
      const currentToggles =
        queryClient.getQueryData<Record<string, boolean>>([FEATURE_TOGGLE_QUERY_KEY]) ?? {};
      const currentTogglesClone = { ...currentToggles };
      delete currentTogglesClone[key];
      window?.localStorage?.setItem(
        FEATURE_TOGGLE_STORAGE_KEY,
        JSON.stringify(currentTogglesClone)
      );
      queryClient.setQueryData([FEATURE_TOGGLE_QUERY_KEY], currentTogglesClone);
    },
  });

  const getFeatureToggle = (key: ToggleKey): boolean => featureToggles[key] ?? false;

  const listActiveFeatureToggles = (): TOGGLES_MAP => {
    const initialToggles = TOGGLE_KEYS.reduce(
      (acc, curr) => ({ ...acc, [curr]: false }),
      {} as TOGGLES_MAP
    );
    return { ...initialToggles, ...DEFAULT_FEATURE_TOGGLES, ...featureToggles };
  };

  return {
    getFeatureToggle,
    setFeatureToggle: setFeatureToggle.mutateAsync,
    removeFeatureToggle: removeFeatureToggle.mutateAsync,
    listActiveFeatureToggles,
  };
};

export const useGetFeatureToggle = (key: ToggleKey) => {
  const { getFeatureToggle } = useFeatureToggles();
  return getFeatureToggle(key);
};

export default useFeatureToggles;

