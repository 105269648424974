import { NuqsAdapter } from 'nuqs/adapters/react-router';
import { HTMLAttributes } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
} from 'react-router-dom';
import './globals.css';

// Import Standalone Pages
import LandingPage from '@/pages/landing/LandingPage';

// Import Login Pages
import AuthenticatePage from '@/pages/login/AuthenticationPage';
import LoginPage from '@/pages/login/LoginPage';

// Import Admin Pages – Scratch for Devs
import AdminDashboardLayout from '@/pages/admin-dash/AdminDashboardLayout';
import AdminCustomersTab from '@/pages/admin-dash/customers/AdminCustomersTab';
import AdminLogoutTab from '@/pages/admin-dash/logout/AdminLogoutTab';
import AdminOverviewTab from '@/pages/admin-dash/overview/AdminOverviewTab';
import AdminScratchTab from '@/pages/admin-dash/scratch/AdminScratchTab';

// Import Customer Pages and Tabs
import CustomerAPITab from '@/pages/customer-dash/api/CustomerAPITab';
import CustomerDashboardLayout from '@/pages/customer-dash/CustomerDashboardLayout';
import CustomerFinanceTab from '@/pages/customer-dash/finance/CustomerFinanceTab';
import CustomerLogoutTab from '@/pages/customer-dash/logout/CustomerLogoutTab';
import CustomerOverviewTab from '@/pages/customer-dash/overview/CustomerOverviewTab';
import CustomerPayoutsTab from '@/pages/customer-dash/payouts/CustomerPayoutsTab';
import CustomerReportingTab from '@/pages/customer-dash/reporting/CustomerReportingTab';
import CustomerSettingsTab from '@/pages/customer-dash/settings/CustomerSettingsTab';
import CustomerTaxesTab from '@/pages/customer-dash/taxes/CustomerTaxesTab';
import CustomerUsersTab from '@/pages/customer-dash/users/CustomerUsersTab';
import CustomerWorkersTab from '@/pages/customer-dash/workers/CustomerWorkersTab';

// Import Application-level Logic & Logic Wrappers
import { AuthGuard } from './wrappers/AuthGuard';
import { CustomerProfileGuard } from './wrappers/CustomerProfileGuard';
import { LoadingGuard } from './wrappers/LoadingGuard';
import { PermissionGuard } from './wrappers/PermissionGuard';

import BGCCustomerOnboardingHome from '@/pages/bgc-customer-onboarding/BGCCustomerOnboardingHome';
import BGCCustomerOnboardingLayout from '@/pages/bgc-customer-onboarding/BGCCustomerOnboardingLayout';
import { LoadingScope, TabPermissions } from '@/shared/types';
import { Spinner } from '@checkrx/pay-component-library';
import { AppContainer, PageContainer } from './wrappers/AppContainers';
import BaseErrorFallback from './wrappers/BaseErrorFallback';
import RoutingError from './wrappers/RoutingErrorFallback';

import { defineCustomElements, JSX as LocalJSX } from '@launchnotes/embed/dist/loader';
defineCustomElements(window);

type StencilToReact<T> = {
  [P in keyof T]?: T[P] &
    Omit<HTMLAttributes<Element>, 'className'> & {
      class?: string;
    };
};

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  export namespace JSX {
    interface IntrinsicElements extends StencilToReact<LocalJSX.IntrinsicElements> {
      token: string;
    }
  }
}

// Define our Router, as well as most of our top-level logic
const router = createBrowserRouter(
  createRoutesFromElements(
    // Top-level Parent Wrapper
    <Route
      path="/"
      element={
        <AppContainer>
          <PageContainer>
            <LoadingGuard loadingScope={LoadingScope.global}>
              <ErrorBoundary FallbackComponent={BaseErrorFallback}>
                {/* NOTE(Carter): If Auth token is changed, we will remount current page! */}
                <Outlet />
              </ErrorBoundary>
            </LoadingGuard>
          </PageContainer>
        </AppContainer>
      }
      // Routing Error Boundary
      errorElement={<RoutingError />}
    >
      {/* Top Level Pages! */}
      {/* Landing Page */}
      <Route index element={<LandingPage />} />

      {/* Login Page */}
      <Route path="login" element={<LoginPage />} />
      <Route path="authenticate" element={<AuthenticatePage />} />

      {/* Admin Dashboard Routes */}
      <Route path="admin" element={<AdminDashboardLayout />}>
        <Route index element={<AdminOverviewTab />} />
        <Route path="buttons" element={<AdminScratchTab />} />
        <Route path="customer" element={<AdminCustomersTab />} />
        <Route path="logout" element={<AdminLogoutTab />} />
      </Route>

      {/* BGC customer Onboarding */}
      <Route path="bgc-customer-onboarding" element={<BGCCustomerOnboardingLayout />}>
        <Route index element={<BGCCustomerOnboardingHome />} />,
      </Route>

      {/* Customer Dashboard Routes */}
      <Route
        path="customer"
        element={
          <AuthGuard>
            <CustomerProfileGuard>
              {/*
               * NOTE(Carter): The Auth Guard and Customer Profile Guard together ensure
               * that on any /customer render, we have a stored authToken and we've used
               * it to fetch the CustomerProfile from the API
               */}
              <CustomerDashboardLayout />
            </CustomerProfileGuard>
          </AuthGuard>
        }
      >
        <Route index element={<CustomerOverviewTab />} />
        <Route
          path="payouts"
          element={
            <PermissionGuard
              permissionRequired={[{ tab: 'payoutsTab', permissionRole: TabPermissions.read }]}
              redirect="/customer/workers"
            >
              <CustomerPayoutsTab />
            </PermissionGuard>
          }
        />
        <Route
          path="workers"
          element={
            <PermissionGuard
              permissionRequired={[{ tab: 'workersTab', permissionRole: TabPermissions.read }]}
            >
              <CustomerWorkersTab />
            </PermissionGuard>
          }
        />
        <Route
          path="taxes"
          element={
            <PermissionGuard
              permissionRequired={[{ tab: 'taxTab', permissionRole: TabPermissions.read }]}
            >
              <CustomerTaxesTab />
            </PermissionGuard>
          }
        />
        <Route
          path="finance"
          element={
            <PermissionGuard
              permissionRequired={[{ tab: 'financeTab', permissionRole: TabPermissions.read }]}
            >
              <CustomerFinanceTab />
            </PermissionGuard>
          }
        />
        <Route
          path="api"
          element={
            <PermissionGuard
              permissionRequired={[{ tab: 'apiTab', permissionRole: TabPermissions.read }]}
            >
              <CustomerAPITab />
            </PermissionGuard>
          }
        />
        <Route
          path="users"
          element={
            <PermissionGuard
              permissionRequired={[{ tab: 'usersTab', permissionRole: TabPermissions.read }]}
            >
              <CustomerUsersTab />
            </PermissionGuard>
          }
        />
        <Route
          path="settings"
          element={
            <PermissionGuard
              permissionRequired={[{ tab: 'settingsTab', permissionRole: TabPermissions.read }]}
            >
              <CustomerSettingsTab />
            </PermissionGuard>
          }
        />
        <Route
          path="reporting"
          element={
            <CustomerReportingTab />
          }
        />
        <Route path="logout" element={<CustomerLogoutTab />} />
      </Route>
    </Route>
  )
);

// Default is a spinner centered in the whole page
const Fallback = () => <Spinner size="100vw" />;

export default function App() {
  return (
    <NuqsAdapter>
      <RouterProvider router={router} fallbackElement={<Fallback />} />
    </NuqsAdapter>
  );
}

