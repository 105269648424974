import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { getBatchUploadErrorMessage, useBatchFileCreate } from '@/services/useBatchFiles';
import { BatchFileType } from '@/shared/types';
import { Icon } from '@checkrx/pay-component-library';
import dayjs from 'dayjs';
import { useState } from 'react';
import { WorkerWithEarnings } from '../taxes.types';
import { TaxTotals } from './TaxTotals';
import { taxWizardColorMap } from './utils';

type Props = {
  workers: WorkerWithEarnings[];
  lessThan600Workers: WorkerWithEarnings[];
};

export const ExternalEarningsStep = ({ workers, lessThan600Workers }: Props) => {
  const [file, setFile] = useState<File | null>(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const { isLoading: batchCreateIsLoading, mutate: uploadBatchFile } = useBatchFileCreate();

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      setFile(selectedFile);
      setSuccessMessage('');
      setErrorMessage('');
    }
  };

  const handleUpload = async () => {
    if (!file) return;
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target?.result as string;
      const lines = text.split('\n');

      const transformedLines = lines
        .map((line, index) => {
          if (index === 0) {
            return 'workerIdType,workerCheckrPayId,manuallyExcluded,nonPlatformEarningsAmount,overrideTotalEarningsAmount,hyphenateLastName';
          }

          const columns = line.split(',');
          if (columns.length >= 3) {
            return `${columns[0]},${columns[1]},,${columns[2]},,`;
          }
          return line;
        })
        .join('\n');

      const transformedFile = new File([transformedLines], file.name, { type: 'text/csv' });
      uploadBatchFile(
        {
          file: transformedFile,
          type: BatchFileType.Taxes1099Update,
          runAt: dayjs().startOf('minute').toDate(),
        },
        {
          onSuccess: () => {
            setSuccessMessage('Successfully uploaded external earnings and awaiting processing');
            setErrorMessage('');
            setFile(null);
          },
          onError: (error) => {
            setErrorMessage(getBatchUploadErrorMessage(error) ?? 'An error occurred during upload');
            setSuccessMessage('');
          },
        }
      );
    };

    reader.readAsText(file);
  };

  const handleSetExternalEarnings = () => {
    handleUpload();
  };

  const sections = [
    {
      title: 'Total Workers',
      count: workers.length,
      accentColor: taxWizardColorMap.default,
      items: workers.map((worker) => ({
        id: worker.id,
        label: (worker.profile?.legalName || worker.profile?.email) ?? '----',
        description: `$${(worker.earnedCentsTotal / 100).toFixed(2)} earned`,
      })),
    },
    {
      title: 'Workers with less than $600',
      count: lessThan600Workers.length,
      accentColor: taxWizardColorMap.excluded,
      items: lessThan600Workers.map((worker) => ({
        id: worker.id,
        label: (worker.profile?.legalName || worker.profile?.email) ?? '----',
        description: 'Less than $600 earned on Checkr Pay',
      })),
      emptyMessage: 'No workers with less than $600 found',
    },
  ];

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">Upload External Earnings (Optional)</h3>
        <p className="text-sm text-gray-500">
          If workers earned income outside the platform that should be included in tax calculations,
          you can upload those earnings here. This step is optional - if workers only earned through
          the platform, you can skip this step.
        </p>
      </div>

      <TaxTotals
        sections={sections}
        defaultExpanded={false}
        defaultExpandedSection="Total Workers"
      />

      <div className="space-y-4">
        <div className="rounded-lg border p-4">
          <h4 className="font-medium mb-2">Upload CSV File</h4>

          <div className="space-y-4">
            <Input
              type="file"
              accept=".csv"
              onChange={handleFileUpload}
              className="cursor-pointer file:cursor-pointer"
            />
          </div>

          <div className="mt-4 space-y-4">
            <div>
              <Tooltip>
                <TooltipTrigger>
                  <div className="flex items-center gap-2 mb-2">
                    <h5 className="text-sm font-medium">Example CSV</h5>
                    <Icon name="help-circle" color="primary" />
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  <div className="text-xs space-y-3 max-w-[400px]">
                    <p>
                      <strong>Required columns:</strong>
                    </p>
                    <div>
                      <p className="font-medium">workerIdType</p>
                      <p className="text-gray-500">
                        Must be either &apos;checkrPayId&apos; or &apos;metadata&apos;
                      </p>
                    </div>
                    <div>
                      <p className="font-medium">workerCheckrPayId</p>
                      <p className="text-gray-500">
                        Worker&apos;s ID - use Checkr Pay ID when workerIdType is
                        &apos;checkrPayId&apos; (e.g. 6e9ce577-4b68-45a6-839a-551a47d670a1) or use
                        customer metadata when workerIdType is &apos;metadata&apos;
                      </p>
                    </div>
                    <div>
                      <p className="font-medium">nonPlatformEarningsAmount</p>
                      <p className="text-gray-500">
                        Amount earned outside Checkr Pay (in cents, e.g. $500 = 50000)
                      </p>
                    </div>
                  </div>
                </TooltipContent>
              </Tooltip>
              <div className="flex items-center gap-2">
                <pre className="text-xs bg-gray-100 p-2 rounded block overflow-x-auto whitespace-pre-wrap break-words max-w-[680px]">
                  workerIdType,workerCheckrPayId,nonPlatformEarningsAmount
                  <br />
                  checkrPayId,6e9ce577-4b68-45a6-839a-551a47d670a1,100000
                </pre>
              </div>
            </div>
          </div>
        </div>
      </div>

      {successMessage && (
        <div className="rounded-lg bg-green-50 p-4 text-green-700">{successMessage}</div>
      )}

      {errorMessage && <div className="rounded-lg bg-red-50 p-4 text-red-700">{errorMessage}</div>}

      <div className="flex items-center justify-end gap-4">
        {file && !successMessage && (
          <p className="text-sm text-amber-500">File selected - click to submit</p>
        )}
        <Button
          onClick={handleSetExternalEarnings}
          variant="outline"
          disabled={!file || batchCreateIsLoading}
        >
          {batchCreateIsLoading ? 'Uploading...' : 'Set External Earnings'}
        </Button>
      </div>
    </div>
  );
};

