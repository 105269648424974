import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { ControlsRow } from '@/shared/components/CreationComponents';
import { BetweenWrapper } from '@/shared/components/Wrappers.styled';
import {
  CustomerProfile,
  PayoutGroupDefinition,
  PayoutGroupDefinitionFilterSelections,
} from '@/shared/types';

type Props = {
  customerProfile?: CustomerProfile;
  onPayoutGroupDefinitionSelect: (arg: PayoutGroupDefinitionFilterSelections) => void;
  payoutGroupDefinitions?: Array<PayoutGroupDefinition>;
};

export default function PayoutGroupDefinitionFilters({
  onPayoutGroupDefinitionSelect,
  payoutGroupDefinitions,
}: Props) {
  // customer has nothing setup
  if (!payoutGroupDefinitions || payoutGroupDefinitions.length === 0) {
    return null;
  }

  const orderedPayoutGroupDefinitions = payoutGroupDefinitions.sort((a, b) => a.order - b.order);

  return (
    <BetweenWrapper>
      <ControlsRow>
        {orderedPayoutGroupDefinitions.map((definition) => (
          <Select
            key={definition.tagKey}
            onValueChange={(value) => {
              const selectedOption = [
                { label: `Any ${definition.name}`, value: '' },
                ...definition.allowedValues.map((v) => ({
                  label: v.name,
                  value: v.tagValue,
                })),
              ].find((opt) => opt.value === value);

              onPayoutGroupDefinitionSelect({
                [definition.name]: value.length === 0 ? null : selectedOption?.label ?? null,
              });
            }}
          >
            <SelectTrigger className="min-w-[180px]">
              <SelectValue placeholder={`Any ${definition.name}`} />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="any">Any {definition.name}</SelectItem>
              {definition.allowedValues.map((value) => (
                <SelectItem key={value.tagValue} value={value.tagValue}>
                  {value.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        ))}
      </ControlsRow>
    </BetweenWrapper>
  );
}

/*
  Show the feature promo for a recent user, or a bit after the feature relase
*/
// function shouldShowFeaturePromo(customerProfile: CustomerProfile | undefined) {
//   if (customerProfile) {
//     const featureLaunchDate = dayjs('2024-03-30');
//     const customerProfileCreationDate = dayjs(customerProfile.createdAt);
//     const effectiveCutoffDate = featureLaunchDate.isAfter(customerProfileCreationDate)
//       ? featureLaunchDate
//       : customerProfileCreationDate;

//     return effectiveCutoffDate.isAfter(dayjs().subtract(1, 'month'));
//   } else {
//     return false;
//   }
// }

