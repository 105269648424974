import { Spinner } from '@checkrx/pay-component-library';
import List, { GenericSection } from '../List/List';

interface ListViewProps<T> {
  sections: GenericSection<T>[][];
  data?: T[];
  header?: React.ReactNode;
  loading?: boolean;
  error?: boolean;
  empty?: boolean;
  hasMore?: boolean;
  onLoadMore?: () => void;
  onRowClick?: (row: T, rowIndex: number) => void;
}

export const ListView = <T,>({
  sections,
  data = [],
  header,
  loading,
  error,
  empty,
  hasMore,
  onLoadMore,
  onRowClick,
}: ListViewProps<T>) => {
  return (
    <div className="w-full h-[calc(100vh-180px)] flex flex-col gap-4">
      {header && <div className="w-full">{header}</div>}
      {loading && data.length === 0 ? (
        <div className="flex justify-center items-center h-full">
          <Spinner size="32px" />
        </div>
      ) : error ? (
        <div className="flex justify-center items-center h-full text-red-500">
          An error occurred while loading the data. Please try again.
        </div>
      ) : empty || data.length === 0 ? (
        <div className="flex justify-center items-center h-full text-gray-500">
          Nothing found. Please try a different search.
        </div>
      ) : (
        <List
          items={data}
          sections={sections}
          loading={loading}
          hasMore={hasMore}
          onLoadMore={onLoadMore}
          onRowClick={onRowClick}
        />
      )}
    </div>
  );
};

