import { Button } from '@/components/ui/button';
import { useBulkFileTaxes, useGetOneTaxes } from '@/services/useWorkerTaxes';
import { useGetFeatureToggle } from '@/shared/feature-toggles/feature-toggles';
import { Icon } from '@checkrx/pay-component-library';
import { useState } from 'react';
import { WorkerWithEarnings } from '../taxes.types';
import { TAX_YEARS, viewTaxPdf } from '../utils';
import { TaxTotals } from './TaxTotals';
import { taxWizardColorMap } from './utils';

type Props = {
  workers: WorkerWithEarnings[];
  workersReadyToFile: WorkerWithEarnings[];
};

export const FileTaxesStep = ({ workers, workersReadyToFile }: Props) => {
  const lockTaxes = useGetFeatureToggle('lockTaxes');
  const [successMessage, setSuccessMessage] = useState('');
  const bulkFileTaxes = useBulkFileTaxes();

  const { mutateAsync: getOneTaxes } = useGetOneTaxes();

  const handleFile = () => {
    if (workersReadyToFile.length === 0) return;

    bulkFileTaxes.mutate(
      {
        year: TAX_YEARS[0],
      },
      {
        onSuccess: () => {
          setSuccessMessage(`Successfully queued filing for ${workersReadyToFile.length} workers`);
        },
      }
    );
  };

  const handleViewPdf = async (worker: WorkerWithEarnings) => {
    const taxes = await getOneTaxes({
      workerProfileId: worker.id,
      year: TAX_YEARS[0],
    });
    viewTaxPdf(taxes?.payeeUrl ?? '');
  };

  const sections = [
    {
      title: 'Total Workers',
      count: workers.length,
      accentColor: taxWizardColorMap.default,
      items: workers.map((worker) => ({
        id: worker.id,
        label: (worker.profile?.legalName || worker.profile?.email) ?? '----',
        description: `$${(worker.totalEarnings / 100).toFixed(2)} earned`,
      })),
      emptyMessage: 'No workers found',
    },
    {
      title: 'Ready to File',
      count: workersReadyToFile.length,
      accentColor: taxWizardColorMap.readyToFile,
      items: workersReadyToFile.map((worker) => ({
        id: worker.id,
        label:
          (worker.workerBusinessInformation?.businessName ||
            worker.profile?.legalName ||
            worker.profile?.email) ??
          '----',
        description: worker?.['1099nec']?.[TAX_YEARS[0]]?.payerUrl ? (
          <div
            className="cursor-pointer hover:text-blue-600 flex items-center gap-1 flex"
            onClick={() => handleViewPdf(worker)}
          >
            <Icon name="file" />
            View PDF
          </div>
        ) : null,
      })),
      emptyMessage: 'No workers ready to file',
    },
  ];

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">Review and File Taxes</h3>
        <p className="text-sm text-gray-500">
          Please review the information below before filing your taxes.
        </p>
      </div>

      <TaxTotals
        sections={sections}
        defaultExpanded={false}
        defaultExpandedSection="Ready to File"
      />

      <div className="space-y-4">
        <div className="rounded-lg border p-4">
          <h4 className="font-medium mb-2">Important Notes</h4>
          <ul className="list-disc list-inside space-y-1 text-sm text-gray-600">
            <li>Make sure all worker information is accurate</li>
            <li>Verify earnings amounts for each worker</li>
            <li>Ensure all required forms are completed</li>
          </ul>
        </div>
      </div>
      {successMessage && (
        <div className="rounded-lg bg-green-50 p-4 text-green-700">{successMessage}</div>
      )}
      <div className="flex items-center justify-end gap-4">
        {workersReadyToFile.length > 0 && !successMessage && (
          <p className="text-sm text-amber-500">Ready to file - click to submit</p>
        )}
        <Button
          onClick={handleFile}
          disabled={workersReadyToFile.length === 0 || bulkFileTaxes.isLoading || lockTaxes}
          variant="outline"
        >
          {bulkFileTaxes.isLoading ? 'Filing...' : 'File Now'}
        </Button>
      </div>
    </div>
  );
};

