import { Button } from '@/components/ui/button';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { useWorkers } from '@/services/useWorkers';
import { useState } from 'react';
import { getWorkersWithPayoutTotals, TAX_YEARS } from '../utils';
import { ExternalEarningsStep } from './ExternalEarningsStep';
import { FileTaxesStep } from './FileTaxesStep';
import { Generate1099Step } from './Generate1099Step';
import { StartTaxesStep } from './StartTaxesStep';
import { checkIsEligibleToFile, checkIsEligibleToGenerate, checkIsLessThan600 } from './utils';

export type TaxWizardStep = 'start' | 'earnings' | 'generate' | 'file';
export const taxWizardSteps: TaxWizardStep[] = ['start', 'earnings', 'generate', 'file'];

export function TaxWizard() {
  const { data: workers } = useWorkers(null, '', '', '', '', true);

  const [open, setOpen] = useState(false);
  const [stepHistory, setStepHistory] = useState<TaxWizardStep[]>(['start']);
  const [isNormalFlow, setIsNormalFlow] = useState(true);
  const currentStep = stepHistory[stepHistory.length - 1] as TaxWizardStep;
  const nextStep = taxWizardSteps[taxWizardSteps.indexOf(currentStep) + 1] as TaxWizardStep;

  const handleBack = () => {
    if (stepHistory.length <= 1) {
      setOpen(false);
      return;
    }
    if (stepHistory.length === 2) {
      setIsNormalFlow(true);
    }

    const newHistory = [...stepHistory];
    newHistory.pop();
    setStepHistory(newHistory);
  };

  const handleClose = () => {
    setOpen(false);
    // wait for dialog to close before resetting step
    setTimeout(() => {
      setIsNormalFlow(true);
      setStepHistory(['start']);
    }, 100);
  };

  const handleNext = () => {
    if (currentStep === 'file' || !isNormalFlow) {
      handleClose();
      return;
    }
    setStepHistory([...stepHistory, nextStep]);
  };

  const handleStepChange = (step: TaxWizardStep) => {
    setIsNormalFlow(false);
    setStepHistory([...stepHistory, step]);
  };

  const isNextDisabled = () => {
    const validationMap = {
      start: false,
      earnings: false,
      generate: false,
      file: false,
    };

    return validationMap[currentStep] ?? false;
  };

  if (!workers) return <Button disabled>Loading tax wizard...</Button>;

  const workersWithPayoutTotals = getWorkersWithPayoutTotals(workers ?? [], TAX_YEARS[0]);

  const workersReadyToGenerate = workersWithPayoutTotals.filter(checkIsEligibleToGenerate);
  const workersReadyToFile = workersWithPayoutTotals.filter(checkIsEligibleToFile);
  const lessThan600Workers = workersWithPayoutTotals.filter(checkIsLessThan600);

  const renderStepContent = () => {
    const stepComponents = {
      start: (
        <StartTaxesStep
          workers={workersWithPayoutTotals}
          workersReadyToGenerate={workersReadyToGenerate}
          workersReadyToFile={workersReadyToFile}
          setCurrentStep={handleStepChange}
        />
      ),
      earnings: (
        <ExternalEarningsStep
          workers={workersWithPayoutTotals}
          lessThan600Workers={lessThan600Workers}
        />
      ),
      generate: (
        <Generate1099Step
          workers={workersWithPayoutTotals}
          workersReadyToGenerate={workersReadyToGenerate}
        />
      ),
      file: (
        <FileTaxesStep workers={workersWithPayoutTotals} workersReadyToFile={workersReadyToFile} />
      ),
    } as const;

    return stepComponents[currentStep];
  };

  return (
    <>
      <Button onClick={() => setOpen(true)}>Open Tax Wizard</Button>

      <Dialog open={open} onOpenChange={handleClose}>
        <DialogContent className="max-w-2xl">
          <div className="py-4">{renderStepContent()}</div>

          <div className="flex justify-between">
            <Button variant="outline" onClick={handleBack}>
              {currentStep === 'start' ? 'Cancel' : 'Back'}
            </Button>
            <Button onClick={handleNext} disabled={isNextDisabled()}>
              {currentStep === 'file' || !isNormalFlow ? 'Complete' : 'Next'}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

