import dayjs from 'dayjs';
import { useQueryState } from 'nuqs';
import { useMemo, useState } from 'react';

import { toast } from '@/app/wrappers/Toaster/Toaster';
import { DateRangeSelector } from '@/components/ui/date-range-selector';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { parseApiErrorDetails } from '@/services/serverApi';
import { useBatchFileCancel, useBatchFiles } from '@/services/useBatchFiles';
import { GenericSection } from '@/shared/components/List/List';
import { ListView } from '@/shared/components/ListView/ListView';
import { BatchFile, BatchFileStatus, BatchFileType } from '@/shared/types';
import { PayoutBatchStatusText } from './PayoutBatchStatusText';
import { PayoutsBatchSheet } from './PayoutsBatchSheet';

export default function PayoutsBatchFileTable() {
  const defaultStartDate = useMemo(() => dayjs().subtract(1, 'week').startOf('day').toDate(), []);
  const defaultEndDate = useMemo(() => dayjs().add(1, 'day').toDate(), []);

  const [startDate, setStartDate] = useQueryState('startDate', {
    parse: (value) => dayjs(value).toDate(),
    defaultValue: defaultStartDate,
  });
  const [endDate, setEndDate] = useQueryState('endDate', {
    parse: (value) => dayjs(value).toDate(),
    defaultValue: defaultEndDate,
  });

  const [searchString, setSearchString] = useQueryState('search', { defaultValue: '' });
  const [selectedStatus, setSelectedStatus] = useQueryState('status', { defaultValue: 'any' });
  const [selectedBatchFile, setSelectedBatchFile] = useState<BatchFile>();
  const [showBatchSheet, setShowBatchSheet] = useState(false);

  const {
    data: batchFiles,
    isLoading: batchFilesIsLoading,
    isError: batchFilesIsError,
  } = useBatchFiles(
    startDate,
    endDate,
    BatchFileType.Payout,
    selectedStatus !== 'any' ? (selectedStatus as BatchFileStatus) : undefined
  );

  const { mutateAsync: cancelBatchFile } = useBatchFileCancel();

  const handleCancelBatchFile = async (batchFileId: string) => {
    try {
      await cancelBatchFile({ batchFileId });
      toast({
        message: 'Batch file cancelled',
        type: 'success',
        duration: 5000,
      });
    } catch (err) {
      let message = 'Failed to cancel batch file';
      if (parseApiErrorDetails(err) === 'invalid_status') {
        message = 'This batch file has already been processed and cannot be cancelled';
      }
      toast({
        message,
        type: 'error',
        duration: 5000,
      });
    }
  };

  const sections: GenericSection<BatchFile>[][] = [
    [
      {
        id: 'fileName',
        title: 'File Name',
        content: (row) => row.inputFileName,
        width: 200,
      },
      {
        id: 'dateUploaded',
        title: 'Date Uploaded',
        content: (row) => dayjs(row.createdAt).format('D MMM YYYY, h:mma'),
        width: 150,
      },
      {
        id: 'scheduledFor',
        title: 'Scheduled For',
        content: (row) => dayjs(row.runAt || row.createdAt).format('D MMM YYYY, h:mma'),
        width: 150,
      },
      {
        id: 'status',
        title: 'Status',
        content: (row) => <PayoutBatchStatusText status={row.status} />,
        width: 100,
      },
      {
        id: 'uploader',
        title: 'Uploader',
        content: (row) => row.customerProfile?.email || 'Admin',
        width: 150,
      },
      {
        id: 'successCount',
        title: '# Success',
        content: (row) => row.successCount,
        width: 100,
      },
      {
        id: 'failureCount',
        title: '# Error',
        content: (row) => row.failureCount,
        width: 100,
      },
    ],
  ];

  return (
    <>
      <div className="flex flex-col gap-4 bg-primaryWhite p-4 h-full">
        <ListView
          sections={sections}
          data={batchFiles || []}
          loading={batchFilesIsLoading}
          error={batchFilesIsError}
          onRowClick={(row) => {
            setSelectedBatchFile(row);
            setShowBatchSheet(true);
          }}
          header={
            <div className="flex w-full min-w-fit h-fit items-start justify-between">
              <div className="flex flex-row items-center justify-start w-fit gap-4">
                <Input
                  value={searchString}
                  className="min-w-[240px]"
                  placeholder="Filter by search term"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchString(e.target.value || '');
                  }}
                />
                <DateRangeSelector
                  defaultStartDate={defaultStartDate}
                  defaultEndDate={defaultEndDate}
                  onChange={(_, start, end) => {
                    setStartDate(start);
                    setEndDate(end);
                  }}
                  className="max-w-[480px]"
                />
                <Select onValueChange={(value) => setSelectedStatus(value)} defaultValue="any">
                  <SelectTrigger className="min-w-[140px]">
                    <SelectValue placeholder="Select status" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="any">Any Status</SelectItem>
                    <SelectItem value={BatchFileStatus.Pending}>Pending</SelectItem>
                    <SelectItem value={BatchFileStatus.Started}>Started</SelectItem>
                    <SelectItem value={BatchFileStatus.Success}>Completed</SelectItem>
                    <SelectItem value={BatchFileStatus.ManualReview}>Manual Review</SelectItem>
                    <SelectItem value={BatchFileStatus.Error}>Error</SelectItem>
                    <SelectItem value={BatchFileStatus.Cancelled}>Cancelled</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
          }
        />
      </div>
      <PayoutsBatchSheet
        batchFile={selectedBatchFile}
        open={showBatchSheet}
        onOpenChange={setShowBatchSheet}
        onCancelBatchFile={handleCancelBatchFile}
      />
    </>
  );
}

