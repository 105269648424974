'use client';

import dayjs from 'dayjs';
import { Calendar as CalendarIcon } from 'lucide-react';
import { useQueryState } from 'nuqs';
import * as React from 'react';
import { DateRange } from 'react-day-picker';

import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { cn } from '@/lib/utils';

type DateRangeOption =
  | 'all-time'
  | 'last-7-days'
  | 'last-30-days'
  | 'last-week'
  | 'last-month'
  | 'last-90-days'
  | 'custom';

interface DateRangeSelectorProps {
  allTimeStartDate?: Date;
  defaultStartDate?: Date;
  defaultEndDate?: Date;
  onChange?: (range: DateRangeOption, startDate: Date, endDate: Date) => void;
  className?: string;
}

export function DateRangeSelector({
  allTimeStartDate = new Date(2022, 0, 1),
  defaultStartDate = new Date(),
  defaultEndDate = new Date(),
  onChange,
  className,
}: DateRangeSelectorProps) {
  const [dateRange, setDateRange] = React.useState<DateRange | undefined>({
    from: defaultStartDate,
    to: defaultEndDate,
  });
  const [selectedOption, setSelectedOption] = useQueryState<DateRangeOption>('range', {
    defaultValue: 'last-7-days',
    parse: (value) => value as DateRangeOption,
  });
  const [selectingFrom, setSelectingFrom] = React.useState(true);

  const handleOptionChange = (value: DateRangeOption) => {
    setSelectedOption(value);

    const now = dayjs();
    const dateRangeMap: Record<DateRangeOption, { from: Date; to: Date } | undefined> = {
      'all-time': { from: allTimeStartDate, to: new Date() },
      'last-7-days': { from: now.subtract(7, 'day').toDate(), to: new Date() },
      'last-30-days': { from: now.subtract(30, 'day').toDate(), to: new Date() },
      'last-week': {
        from: now.subtract(1, 'week').startOf('week').toDate(),
        to: now.subtract(1, 'week').endOf('week').toDate(),
      },
      'last-month': {
        from: now.subtract(1, 'month').startOf('month').toDate(),
        to: now.subtract(1, 'month').endOf('month').toDate(),
      },
      'last-90-days': { from: now.subtract(90, 'day').toDate(), to: new Date() },
      custom: undefined,
    };

    const newRange = dateRangeMap[value];
    if (!newRange) return;

    setDateRange(newRange);
    onChange?.(value, newRange.from, newRange.to);
  };

  const handleDateRangeChange = (range: DateRange | undefined) => {
    if (!range) return;

    if (selectingFrom) {
      setDateRange({ from: range.from, to: undefined });
      setSelectingFrom(false);
    } else if (range.from && range.to) {
      setDateRange(range);
      setSelectedOption('custom');
      onChange?.('custom', range.from, range.to);
      setSelectingFrom(true);
    }
  };

  return (
    <div className={cn('flex items-center gap-4', className)}>
      <Select value={selectedOption} onValueChange={handleOptionChange}>
        <SelectTrigger className="w-[180px]">
          <SelectValue placeholder="Select range" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="last-7-days">Last 7 Days</SelectItem>
          <SelectItem value="last-30-days">Last 30 Days</SelectItem>
          <SelectItem value="last-week">Last Week</SelectItem>
          <SelectItem value="last-month">Last Month</SelectItem>
          <SelectItem value="last-90-days">Last 90 Days</SelectItem>
          {/* <SelectItem value="all-time">All Time</SelectItem> */}
          <SelectItem value="custom">Custom</SelectItem>
        </SelectContent>
      </Select>

      {selectedOption === 'custom' && (
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              className={cn(
                'w-[280px] justify-start text-left font-normal',
                !dateRange && 'text-muted-foreground'
              )}
            >
              <CalendarIcon className="mr-2 h-4 w-4" />
              {dateRange?.from ? (
                dateRange.to ? (
                  <>
                    {dayjs(dateRange.from).format('MMM DD, YYYY')} -{' '}
                    {dayjs(dateRange.to).format('MMM DD, YYYY')}
                  </>
                ) : (
                  <>
                    {dayjs(dateRange.from).format('MMM DD, YYYY')} -{' '}
                    <span className="text-muted-foreground">Select end date</span>
                  </>
                )
              ) : (
                <span>Pick a date range</span>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar
              initialFocus
              mode="range"
              defaultMonth={dateRange?.from}
              selected={dateRange}
              onSelect={handleDateRangeChange}
              numberOfMonths={2}
            />
          </PopoverContent>
        </Popover>
      )}
    </div>
  );
}

