import dayjs from 'dayjs';
import { useQueryState } from 'nuqs';
import { useMemo, useState } from 'react';

import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

import { DateRangeSelector } from '@/components/ui/date-range-selector';
import { useCustomerPayoutGroupDefinitions } from '@/services/useCustomerProfile';
import { usePayouts } from '@/services/usePayouts';
import { GenericSection } from '@/shared/components/List/List';
import { ListView } from '@/shared/components/ListView/ListView';
import { useGetFeatureToggle } from '@/shared/feature-toggles/feature-toggles';
import {
  CustomerProfile,
  Payout,
  PayoutGroupDefinitionFilterSelections,
  PayoutPurpose,
  PayoutStatus,
} from '@/shared/types';
import { generateAndDownloadCsv } from '@/shared/util';
import { formatCentsAsDollars } from '@/shared/utils/formatters';
import PayoutGroupDefinitionFilters from './PayoutGroupDefinitionFilters';
import { PayoutSheet } from './PayoutSheet';
import { PayoutStatusText } from './PayoutStatusText';

const payoutCompletionDisplayDate = (payout: Payout) => {
  const completed = payout.completedAt || payout.status === PayoutStatus.Completed;
  const completionDate =
    completed && !payout.completedAt ? payout.expectedCompletionDate : payout.completedAt;
  const completionFormat = payout.completedAt ? 'MMM D YYYY, h:mma' : 'MMM D YYYY';
  return completed ? (
    dayjs(completionDate).format(completionFormat)
  ) : payout.expectedCompletionDate ? (
    <div className="flex flex-col">
      <div className="text-textGrey text-[12px]">Expected</div>
      {dayjs(payout.expectedCompletionDate).format('MMM D YYYY')}
    </div>
  ) : null;
};

const payoutCompletionDisplayCsv = (payout: Payout) => {
  return payout.completedAt
    ? dayjs(payout.completedAt).format('MM-DD-YYYY HH:mm:ss')
    : payout.expectedCompletionDate
      ? dayjs(payout.expectedCompletionDate).format('MM-DD-YYYY')
      : null;
};

export const payoutPurposeDisplay = (payout: Payout) => {
  switch (payout.purpose) {
    case PayoutPurpose.Reimbursement:
      return 'Reimbursement';
    case PayoutPurpose.Earning:
    default:
      return 'Earning';
  }
};

type Props = {
  payWorkerId?: string;
  customerProfile: CustomerProfile;
};

export default function PayoutsTable({ payWorkerId, customerProfile }: Props) {
  const allTimeStartDate = useMemo(
    () => dayjs(customerProfile.createdAt).toDate(),
    [customerProfile.createdAt]
  );
  const defaultStartDate = useMemo(() => dayjs().subtract(1, 'week').startOf('day').toDate(), []);
  const defaultEndDate = useMemo(() => dayjs().add(1, 'day').toDate(), []);

  const [startDate, setStartDate] = useQueryState('startDate', {
    parse: (value) => dayjs(value).toDate(),
    defaultValue: defaultStartDate,
  });
  const [endDate, setEndDate] = useQueryState('endDate', {
    parse: (value) => dayjs(value).toDate(),
    defaultValue: defaultEndDate,
  });

  const [searchString, setSearchString] = useQueryState('search', { defaultValue: '' });

  // Worker filtering state
  const [selectedStatus, setSelectedStatus] = useQueryState('status', { defaultValue: 'any' });
  const [workerId] = useState(payWorkerId || '');
  const [workerEmail] = useState('');
  const [workerLegalName] = useState('');

  const [selectedPayout, setSelectedPayout] = useState<Payout | undefined>();
  const [selectedPayoutGroupDefinitions, setSelectedPayoutGroupDefinitions] = useQueryState<
    PayoutGroupDefinitionFilterSelections | undefined
  >('payoutGroupDefinitions', {
    defaultValue: undefined,
    parse: (value) => (value ? JSON.parse(value) : undefined),
    serialize: (value) => (value ? JSON.stringify(value) : ''),
  });
  const [showPayoutSheet, setShowPayoutSheet] = useState(false);

  const {
    data: fetchedPayouts,
    isLoading: isPayoutsLoading,
    isError,
  } = usePayouts({
    startDate: startDate ?? undefined,
    endDate: endDate ?? undefined,
    workerId,
    email: workerEmail,
    legalName: workerLegalName,
    searchString,
    selectedStatus: selectedStatus !== 'any' ? (selectedStatus as PayoutStatus) : null,
    payoutGroupDefinitionFilterSelections: selectedPayoutGroupDefinitions ?? undefined,
  });

  const showPayoutPurpose = useGetFeatureToggle('showPayoutPurpose');

  const { data: payoutGroupDefinitions, isLoading: isPayoutGroupDefinitionsLoading } =
    useCustomerPayoutGroupDefinitions();

  const isLoading = isPayoutsLoading || isPayoutGroupDefinitionsLoading;

  const sections: GenericSection<Payout>[][] = [
    [
      {
        id: 'email',
        title: 'Email',
        content: (row) => row.workerResource?.profile?.email,
        width: 150,
      },
      {
        id: 'name',
        title: 'Name',
        content: (row) => row.workerResource?.profile?.legalName,
        width: 150,
        minScreenWidth: 1412,
      },
      {
        id: 'description',
        title: 'Description',
        content: (row) => row.description,
        width: 150,
        minScreenWidth: 1068,
      },
      {
        id: 'createdDate',
        title: 'Created',
        content: (row) => dayjs(row.createdAt).format('MM/DD/YYYY'),
        width: 150,
        minScreenWidth: 1068,
      },
      {
        id: 'completionDate',
        title: 'Completion Date',
        content: (row) => payoutCompletionDisplayDate(row),
        width: 168,
      },
      {
        id: 'status',
        title: 'Status',
        content: (row) => <PayoutStatusText payout={row} />,
        width: 100,
      },
      {
        id: 'amount',
        title: 'Amount',
        content: (row) => formatCentsAsDollars(row.amountCents),
        width: 100,
      },
      ...(showPayoutPurpose
        ? [
            {
              id: 'purpose',
              title: 'Purpose',
              content: (row: Payout) => payoutPurposeDisplay(row),
              width: 120,
              minScreenWidth: 1068,
              tooltipText:
                "Payouts with purpose 'Earning' (the default) are included in Checkr Pay's tax calculations, whereas payouts with 'Reimbursement' purpose are not.  This may be set when creating payouts via API.",
            },
          ]
        : []),
    ],
    [
      {
        id: 'metadata',
        title: 'Metadata',
        content: (row) => row.metadata,
        width: 200,
        minScreenWidth: 1524,
      },
    ],
  ];

  return (
    <>
      <div className="flex bg-primaryWhite p-4 h-full">
        <ListView
          sections={sections}
          data={fetchedPayouts}
          loading={isLoading}
          error={isError}
          onRowClick={(row) => {
            setSelectedPayout(row);
            setShowPayoutSheet(true);
          }}
          header={
            <div className="flex flex-col w-full gap-2">
              <div className="flex w-full min-w-fit  items-start justify-between">
                <div className="flex flex-row items-center justify-start w-fit gap-4">
                  <Input
                    value={searchString}
                    className="min-w-[240px]"
                    placeholder="Filter by search term"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setSearchString(e.target.value || '');
                    }}
                  />
                  <DateRangeSelector
                    allTimeStartDate={allTimeStartDate}
                    defaultStartDate={defaultStartDate}
                    defaultEndDate={defaultEndDate}
                    onChange={(_, start, end) => {
                      setStartDate(start);
                      setEndDate(end);
                    }}
                    className="max-w-[480px]"
                  />
                  <Select onValueChange={(value) => setSelectedStatus(value)} defaultValue="any">
                    <SelectTrigger className="min-w-[140px]">
                      <SelectValue placeholder="Select status" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="any">Any Status</SelectItem>
                      <SelectItem value={PayoutStatus.Queued}>Queued</SelectItem>
                      <SelectItem value={PayoutStatus.Pending}>Pending</SelectItem>
                      <SelectItem value={PayoutStatus.Completed}>Completed</SelectItem>
                      <SelectItem value={PayoutStatus.Canceled}>Canceled</SelectItem>
                      <SelectItem value={PayoutStatus.Failed}>Failed</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <Button
                  variant="secondary"
                  onClick={() =>
                    generateAndDownloadCsv(fetchedPayouts || [], (payout) => ({
                      payoutAmount: formatCentsAsDollars(payout.amountCents),
                      payoutId: payout.id,
                      workerId: payout.workerId,
                      workerEmail: payout.workerResource?.profile?.email,
                      completionDate: payoutCompletionDisplayCsv(payout),
                      payoutStatus: payout.status,
                      payoutSummary: payout.description,
                      metadata: payout?.metadata || '',
                      ...(payoutGroupDefinitions || []).reduce(
                        (all, definition) => {
                          all[definition.name] = payout.groups?.[definition.name] || '';
                          return all;
                        },
                        {} as Record<string, string>
                      ),
                      ...(showPayoutPurpose ? { payoutPurpose: payout.purpose || '' } : {}),
                    }))
                  }
                >
                  Generate CSV
                </Button>
              </div>
              <PayoutGroupDefinitionFilters
                customerProfile={customerProfile}
                payoutGroupDefinitions={payoutGroupDefinitions}
                onPayoutGroupDefinitionSelect={(
                  selectedDefinitions: PayoutGroupDefinitionFilterSelections
                ) => {
                  setSelectedPayoutGroupDefinitions(selectedDefinitions);
                }}
              />
            </div>
          }
        />
      </div>
      <PayoutSheet
        payout={selectedPayout}
        open={showPayoutSheet}
        onOpenChange={setShowPayoutSheet}
      />
    </>
  );
}

