/**
 * The Customer Reporting tab is for displaying custom
 */
import { useCustomer } from '@/services/useCustomerProfile';
import { PageLayout } from '@/shared/components/PageLayout/PageLayout';
import Retool from 'react-retool';

export default function CustomerReportingTab() {
  const { data: customer } = useCustomer();
  const retoolData = { name: 'Sample data' }

  if (customer?.featureFlags?.reportingEnabled?.enabled) {
    return (
      <PageLayout title="Reporting">
        <Retool
          url="https://checkrpay.retool.com/embedded/public/65f04521-f0f4-4144-8138-fde76cc3f14b"
          data={retoolData}
        />
      </PageLayout>
    );
  }

  return (
    <PageLayout title="Reporting">
        <div className="flex w-full h-full flex-col justify-center items-center mb-64">
          <div className="flex flex-col items-center gap-1 mb-6">
            <p className="text-3xl">✨ Coming Soon! ✨</p>
          </div>

          <div className="flex flex-col items-center gap-1 mb-6">
            <p>
              📊 We&apos;re working hard on bringing
              you an amazing reporting experience.
            </p>
            <p>🎉 Stay tuned - we can&apos;t wait to show you what we&apos;re building! 🌟</p>
          </div>
        </div>
      </PageLayout>
  );
}
