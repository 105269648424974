import { DateString } from './types';

export enum TransactionType {
  Ach = 'Ach',
  Wire = 'Wire',
  BookTransfer = 'BookTransfer',
  Card = 'Card',
  Atm = 'Atm',
  Check = 'Check',
  Dispute = 'Dispute',
  Interest = 'Interest',
  Fee = 'Fee',
  Other = 'Other',
}

export enum TransactionDirection {
  Credit = 'Credit',
  Debit = 'Debit',
}

export enum TransactionStatus {
  Pending = 'Pending',
  PendingReview = 'PendingReview',
  Clearing = 'Clearing',
  Returned = 'Returned',
  Sent = 'Sent',
  Rejected = 'Rejected',
  Canceled = 'Canceled',
  Authorized = 'Authorized',
}

type PayoutGroups = {
  [key: string]: string | undefined;
}

export type TransactionMetadata = {
  customer?: string;
  customerFee?: string;
  isPayout?: string;
  isRepayment?: string; // of boolean;
  payoutId?: string;
  payoutMethod?: string;
  profile?: string;
  queuedPayoutId?: string;
  queuedPayoutIdInternal?: string;
  worker?: string;
} & PayoutGroups

export type Transaction = {
  accountId: string;
  amount: number;
  createdAt: DateString;
  direction: string;
  metadata?: TransactionMetadata;
  startedAt: DateString;
  summary: string;
  description?: string;
  transactionId: string;
  transactionStatus: TransactionStatus;
  transactionType: TransactionType;
  userId: string;
}
