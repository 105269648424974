import { toast } from '@/app/wrappers/Toaster/Toaster';
import { Button } from '@/components/ui/button';
import { DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { useRequestCorrection } from '@/services/useWorkerTaxes';
import DollarAmount from '@/shared/components/DollarAmount';
import { useState } from 'react';
import { WorkerWithEarnings } from '../taxes.types';
import { TAX_YEARS } from '../utils';

type Props = {
  worker: WorkerWithEarnings;
  onSuccess?: () => void;
  onClose?: () => void;
};

export const RequestIndividualCorrection = ({ worker, onSuccess, onClose }: Props) => {
  const [externalEarnings, setExternalEarnings] = useState<number>(
    Number(worker?.['1099nec']?.[TAX_YEARS[0]]?.nonPlatformIncome ?? 0)
  );
  const [inputValue, setInputValue] = useState(
    externalEarnings
      ? Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(externalEarnings / 100)
      : ''
  );
  const requestCorrection = useRequestCorrection();

  const handleRequestCorrection = () => {
    requestCorrection.mutate(
      {
        workerProfileId: worker.id,
        year: TAX_YEARS[0],
        nonPlatformEarningsAmount: Number(externalEarnings),
      },
      {
        onSuccess: () => {
          toast({
            message: 'Successfully queued correction request',
            type: 'success',
            duration: 5000,
          });
          onSuccess?.();
        },
        onError: () => {
          toast({
            message: 'Failed to request correction',
            type: 'error',
            duration: 5000,
          });
        },
      }
    );
  };

  const currentExternalEarnings = worker?.['1099nec']?.[TAX_YEARS[0]]?.nonPlatformIncome ?? 0;
  const overrideTotalEarningsAmount =
    worker?.['1099nec']?.[TAX_YEARS[0]]?.overrideTotalEarningsAmount ?? 0;

  return (
    <DialogContent className="max-w-2xl">
      <DialogHeader>
        <DialogTitle>Request 1099-NEC Correction</DialogTitle>
        <p className="text-sm text-muted-foreground">
          Request a correction for {worker.profile?.legalName || worker.profile?.email}&apos;s
          1099-NEC form.
        </p>
      </DialogHeader>

      <div className="py-4 space-y-6">
        <div className="rounded-md border p-4 space-y-4">
          <div className="flex justify-between items-center">
            <div>
              <p className="font-medium">{worker.profile?.legalName || worker.profile?.email}</p>
              <p className="text-sm text-gray-500">
                <DollarAmount amountCents={worker.earnedCentsTotal} /> earned on platform
              </p>
              {currentExternalEarnings ? (
                <p className="text-sm text-gray-500">
                  <DollarAmount amountCents={currentExternalEarnings} /> earned externally
                </p>
              ) : null}
              {overrideTotalEarningsAmount > 0 && (
                <p className="text-sm text-amber-500 mt-2">
                  Note: Total earnings have been manually overridden to{' '}
                  <DollarAmount amountCents={overrideTotalEarningsAmount} />
                </p>
              )}
            </div>
          </div>

          <div className="space-y-2">
            <span className="text-sm font-medium">Updated external earnings</span>
            <Input
              className="w-[280px]"
              placeholder="Updated external earnings"
              value={inputValue}
              onChange={(e) => {
                const value = e.target.value.replace(/\D/g, '');
                if (value === '') {
                  setInputValue('');
                  setExternalEarnings(0);
                  return;
                }
                const formattedValue = Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).format(parseInt(value) / 100);
                setInputValue(formattedValue);
                setExternalEarnings(parseInt(value));
              }}
            />
          </div>

          <div className="flex justify-between text-sm">
            <span className="font-medium">Total earnings:</span>
            <DollarAmount amountCents={worker.totalEarnings} />
          </div>
        </div>
      </div>

      <div className="flex justify-between">
        <Button variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleRequestCorrection} disabled={requestCorrection.isLoading}>
          {requestCorrection.isLoading ? 'Requesting...' : 'Request Correction'}
        </Button>
      </div>
    </DialogContent>
  );
};

